"use strict";

$(document).ready(function () {
  /* Components */
  $(function () {
    $('body').on('click', 'a[href="#"]', function (event) {
      return event.preventDefault();
    });
    $('.js-is-scrolled-page').each(function () {
      var block = $(this);
      $(window).on('scroll', function () {
        if ($('html')[0].scrollTop > 0) {
          block.addClass('page-is-scrolled');
        } else {
          block.removeClass('page-is-scrolled');
        }
      });
      $(window).scroll();
    });
    $('.js-add-active-class-btn').each(function () {
      var btn = $(this);
      var id = btn.attr('data-add-active-class-id');
      var target = $('.js-add-active-class-target[data-add-active-class-id="' + id + '"]');
      var targetRecheck = $('.js-add-active-class-target-recheck[data-add-active-class-id="' + id + '"]');
      btn.on('click', function () {
        setTimeout(checkAndSet, 10);
      });
      targetRecheck.on('transitionend', function () {
        checkAndSet();
      });

      function checkAndSet() {
        if (btn.attr('aria-expanded') === 'true') {
          target.addClass('active');
        } else {
          target.removeClass('active');
        }
      }
    });
  });
});